<template>
  <div class="home">
    <img src="../assets/image/left.jpg" alt="">
    <img src="../assets/image/center.jpg" alt="">
    <img src="../assets/image/right.jpg" alt="">
    <img src="../assets/image/KOLBOXSocial.png" alt="" class="title title1">
    <img src="../assets/image/0002.png" alt="" class="title title2">
  </div>
</template>

<script>
  export default {
    name: "Home",
    setup() {}
  };

</script>
<style lang="less" scoped>
  .home {
    width: 100%;
    height: calc(100vh - 1px);
    display: flex;
    overflow: hidden;
    position: relative;

    img {
      min-height: 100%;
      min-width: 33.33%;
      height: auto;
    }

    .title {
      position: absolute;
      left: 65px;
      bottom: 60px;
      width: 932px;
      height: 104px;
      min-height: 0;
      min-width: 0;
    }

    .title1 {
      bottom: 200px;
      width: 607px;
      height: 77px;
    }

  }

</style>
