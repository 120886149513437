import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
import "./assets/font/font.css"
// import * as OfficeHelpers from "@microsoft/office-js-helpers";

const app = createApp(App)
app.use(store).use(router).mount('#app')
