import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/twoLevel',
    name: 'TwoLevel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "twoLevel" */ '../views/TwoLevel.vue'),
    children: [
      {
        path: '/weDo',
        name: 'WeDo',
        component: () => import(/* webpackChunkName: "WeDo" */ '../views/WeDo.vue')
      },
      {
        path: '/talent',
        name: 'Talent',
        component: () => import(/* webpackChunkName: "Talent" */ '../views/Talent.vue')
      },
      {
        path: '/brands',
        name: 'Brands',
        component: () => import(/* webpackChunkName: "Brands" */ '../views/Brands.vue')
      },
      {
        path: '/contactUs',
        name: 'ContactUs',
        component: () => import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs.vue')
      },
      {
        path: '/wordDemo2',
        name: 'WordDemo2',
        component: () => import(/* webpackChunkName: "WordDemo2" */ '../views/wordDemo2.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
