<template>
  <div>

    <ul class="nav" :class="{currentHome:currentRoute === '/'}">
      <img style="width: 118px;height:92px" src="./assets/image/kolbox.png" alt="" v-if="currentRoute === '/'">
      <img  style="width: 118px;height:92px" src="./assets/image/kolbox.png" alt="" v-else>
      <li :class="{ currentRoute: currentRoute === '/' }">
        <router-link :to="'/'">Home</router-link>
        <div class="current"></div>
      </li>
      <li :class="{ currentRoute: currentRoute === '/weDo' }">
        <router-link :to="'/weDo'">What We Do</router-link>
        <div class="current"></div>
      </li>
      <li :class="{ currentRoute: currentRoute === '/talent' }">
        <router-link :to="'/talent'">Influencer</router-link>
        <div class="current"></div>
      </li>
      <li :class="{ currentRoute: currentRoute === '/brands' }">
        <router-link :to="'/brands'">Brands</router-link>
        <div class="current"></div>
      </li>
      <li :class="{ currentRoute: currentRoute === '/contactUs' }">
        <router-link :to="'/contactUs'">Contact Us</router-link>
        <div class="current"></div>
      </li>
    </ul>
    <div class="container">
      <router-view />
    </div>
  </div>
</template>
<script>
  import {
    useRoute
  } from "vue-router";
  import {
    computed,
    toRaw
  } from "vue";
  export default {
    setup() {
      const route = useRoute();
      const currentRoute = computed(() => toRaw(route).path.value);
      return {
        currentRoute
      };
    }
  };

</script>

<style lang="less">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .nav {
    position: fixed;
    top: 50px;
    z-index: 20;

    img {
      margin-left: 72px;
      margin-bottom: 50px;
      width: 131px;
      height: 86px;
    }

    li {
      height: 40px;
      line-height: 40px;
      width: 300px;
      margin-bottom: 10px;

      a {
        margin-left: 70px;
        font-size: 20px;
        font-family: "MyFont";
        font-weight: normal;
        color: #000;
      }

      .current {
        width: 80px;
        height: 6px;
        background: #E60012;
        transform: translateX(-80px);
        transition: all 0.4s;
      }
    }

    .currentRoute {
      .current {
        transform: translateX(68px);
      }
    }

    li:hover {
      .current {
        transform: translateX(68px);
        transition: all 0.4s;
      }
    }
  }

  .container {
    position: relative;
    width: 100%;
    height: calc(100vh - 1px);
    z-index: 10;
    top: 0;
    padding-top: 1px;
  }


  .currentHome {
    li {
      a {
        color: #FFF;
      }
    }
  }

</style>
